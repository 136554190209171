const THEME_IDS = Object.freeze({
  COMMON: 'common',
  BLUE: 'blue',
})

const AVAILABLE_THEME_VARIABLES = Object.freeze({
  PRIMARY_COLOR: '--primary-color',
  BASE_FOCUS_COLOR: '--base-focus-color',
  LINK_COLOR: '--link-color',
  SUCCESS_COLOR: '--success-color',
})

const config = {
  defaultGroupName: 'Common',
  applicationGroups: [
    {
      name: 'Common',
      themeId: THEME_IDS.COMMON,
      logoUrl: `${process.env.SSO_URL || ''}/logos/norstella_tm.svg`,
      logoBackground: '#14123b',
      signup: {
        full: {
          isConsentRequired: false,
          terms:
            `<label for="consent" class="app-terms">
                Your personal information will be used in accordance with our <a href="https://www.norstella.com/global-privacy-notice/" target="_blank" rel="noopener nofollow">privacy notice</a>.
                We may contact you with updates, relevant promotions and information about our products. At any time, 
                you can unsubscribe or manage the types of communication you receive by following the links in any messages 
                we send you.
            </label>`,
        },
        trial: {
          terms:
            `<p>
              During and after your free trial, Citeline will communicate with you by&nbsp;email about your trial
               and very similar products and services that may be&nbsp;of&nbsp;interest to&nbsp;you.
            </p>
            <p>
              We may also contact you with updates, relevant promotions and information about products provided
              by&nbsp;Citeline. Your information will be used in accordance with our
              <a href="https://www.norstella.com/privacy-notice/" target="_blank" rel="noopener nofollow">
                  Privacy notice
              </a> and at any time you can unsubscribe from receiving communications or&nbsp;manage the types of
              communication you receive by&nbsp;following the links in any emails we send you or by emailing
               <a href = "mailto: privacy@norstella.com">privacy@norstella.com</a>
            </p>`,
          header:
            `<h1>Start your 7-day FREE trial to&nbsp;Citeline News and Insights today!</h1>
             <p>Your trial covers one&nbsp;of the following:</p>
             <ul>
                 <li>Pink Sheet</li>
                 <li>Scrip</li>
                 <li>Medtech Insight</li>
                 <li>In Vivo</li>
                 <li>Generics Bulletin</li>
                 <li>HBW Insight</li>
             </ul>
             <hr class="app-delimeter">`,
        },
      },
      resetPassword: {
        clientServicesSection:
          ``,
      },
      emails: {
        logoUrl: `${process.env.SSO_URL || ''}/logos/norstella_tm.png`,
        email_footer_team_signature: '{{resolved_application_name}}',
        contactClientServiceContent: '',
        legalText: '',
        copyrightText: 'Copyright &copy;&nbsp;Norstella company',

        verify_email_title: 'Welcome to&nbsp;{{resolved_application_name}}!',
        verify_email_body:
          `<p>You need to&nbsp;verify your email address and complete your profile to&nbsp;finish the sign up&nbsp;process.</p>
           <p>This link will expire in&nbsp;\${EMAIL_VERIFY_TTL_HOURS}&nbsp;hours and can be&nbsp;used only once.</p>`,

        welcome_reset_email_link_text: 'Log in now',

        welcome_reset_email_title_regular_user: 'Welcome to&nbsp;{{resolved_application_name}}!',
        welcome_reset_email_body_regular_user:
          `<p
            >Dear {{ user.name }},
            </p>
            <p
            >You will need to&nbsp;set a&nbsp;password when you first log&nbsp;in.
            </p>
            <p
            >This link will expire in&nbsp;\${EMAIL_RESET_PASSWORD_TTL_HOURS}&nbsp;hours and can be&nbsp;used only once.
           </p>`,

        welcome_reset_email_title_api_user: 'Welcome to&nbsp;{{resolved_application_name}}!',
        welcome_reset_email_body_api_user:
          `<p
            >Dear {{ user.name }},
            </p>
            <p
            >You will need to&nbsp;set a&nbsp;password when you first log&nbsp;in.
            </p>
            <p
            >This link will expire in&nbsp;\${EMAIL_RESET_PASSWORD_TTL_HOURS}&nbsp;hours and can be&nbsp;used only once.
           </p>`,

        reset_email_link_text: 'Reset password',

        reset_email_title_regular_user: 'Forgot your password?',
        reset_email_body_regular_user:
          `<p
            >Dear {{ user.name }},
            </p>
            <p
            >We&nbsp;have received a&nbsp;request to&nbsp;reset your password.
            </p>
            <p
            >This link will expire in&nbsp;\${EMAIL_RESET_PASSWORD_TTL_HOURS}&nbsp;hours and can be&nbsp;used only once.
            </p>`,

        reset_email_title_api_user: 'Forgot your API password?',
        reset_email_body_api_user:
          `<p
            >Dear {{ user.name }},
            </p>
            <p
            >We&nbsp;have received a&nbsp;request to&nbsp;reset your API password.
            </p>
            <p
            >This link will expire in&nbsp;\${EMAIL_RESET_PASSWORD_TTL_HOURS}&nbsp;hours and can be&nbsp;used only once.
            </p>`,

        blocked_account_email_title: `Blocked Access Attempt`,
        blocked_account_email_body:
          `<p
            >Dear {{ user.name }},
            </p>
            <p>
                We&nbsp;detected suspicious activity from the&nbsp;ip:: <strong>{{ user.source_ip }}</strong> from
                <strong>{{ user.city }}</strong>, <strong>{{ user.country }}</strong>.
            </p>
            `,
      },
      verifyFooter: {
        text: '',
        linkText: '',
        linkUrl: '',
      },
      errorFooter: {
        text: '',
        linkText: '',
        linkUrl: '',
      },
      fedSignupErrorFooter: {
        text: '',
        linkText: '',
        linkUrl: '',
      },
      contacts: {
        phones: [''],
        email: '',
        link: undefined,
      },
    },
    {
      name: 'Norstella',
      themeId: THEME_IDS.COMMON,
      logoUrl: `${process.env.SSO_URL || ''}/logos/norstella_tm.svg`,
      logoBackground: '#14123b',
      signup: {
        full: {
          isConsentRequired: false,
          terms:
              `<label for="consent" class="app-terms">
                Your personal information will be used in accordance with our <a href="https://www.norstella.com/global-privacy-notice/" target="_blank" rel="noopener nofollow">privacy notice</a>.
                We may contact you with updates, relevant promotions and information about our products. At any time, 
                you can unsubscribe or manage the types of communication you receive by following the links in any messages 
                we send you.
            </label>`,
        },
        trial: {
          terms:
              `<p>
              During and after your free trial, Citeline will communicate with you by&nbsp;email about your trial
               and very similar products and services that may be&nbsp;of&nbsp;interest to&nbsp;you.
            </p>
            <p>
              We may also contact you with updates, relevant promotions and information about products provided
              by&nbsp;Citeline. Your information will be used in accordance with our
              <a href="https://www.norstella.com/privacy-notice/" target="_blank" rel="noopener nofollow">
                  Privacy notice
              </a> and at any time you can unsubscribe from receiving communications or&nbsp;manage the types of
              communication you receive by&nbsp;following the links in any emails we send you or by emailing
               <a href = "mailto: privacy@norstella.com">privacy@norstella.com</a>
            </p>`,
          header:
              `<h1>Start your 7-day FREE trial to&nbsp;Citeline News and Insights today!</h1>
             <p>Your trial covers one&nbsp;of the following:</p>
             <ul>
                 <li>Pink Sheet</li>
                 <li>Scrip</li>
                 <li>Medtech Insight</li>
                 <li>In Vivo</li>
                 <li>Generics Bulletin</li>
                 <li>HBW Insight</li>
             </ul>
             <hr class="app-delimeter">`,
        },
      },
      resetPassword: {
        clientServicesSection:
            ``,
      },
      emails: {
        logoUrl: `${process.env.SSO_URL || ''}/logos/norstella_tm.png`,
        email_footer_team_signature: '{{resolved_application_name}}',
        contactClientServiceContent: '',
        legalText: '',
        copyrightText: 'Copyright &copy;&nbsp;Norstella company',

        verify_email_title: 'Welcome to&nbsp;{{resolved_application_name}}!',
        verify_email_body:
            `<p>You need to&nbsp;verify your email address and complete your profile to&nbsp;finish the sign up&nbsp;process.</p>
           <p>This link will expire in&nbsp;\${EMAIL_VERIFY_TTL_HOURS}&nbsp;hours and can be&nbsp;used only once.</p>`,

        welcome_reset_email_link_text: 'Log in now',

        welcome_reset_email_title_regular_user: 'Welcome to&nbsp;{{resolved_application_name}}!',
        welcome_reset_email_body_regular_user:
            `<p
            >Dear {{ user.name }},
            </p>
            <p
            >You will need to&nbsp;set a&nbsp;password when you first log&nbsp;in.
            </p>
            <p
            >This link will expire in&nbsp;\${EMAIL_RESET_PASSWORD_TTL_HOURS}&nbsp;hours and can be&nbsp;used only once.
           </p>`,

        welcome_reset_email_title_api_user: 'Welcome to&nbsp;{{resolved_application_name}}!',
        welcome_reset_email_body_api_user:
            `<p
            >Dear {{ user.name }},
            </p>
            <p
            >You will need to&nbsp;set a&nbsp;password when you first log&nbsp;in.
            </p>
            <p
            >This link will expire in&nbsp;\${EMAIL_RESET_PASSWORD_TTL_HOURS}&nbsp;hours and can be&nbsp;used only once.
           </p>`,

        reset_email_link_text: 'Reset password',

        reset_email_title_regular_user: 'Forgot your password?',
        reset_email_body_regular_user:
            `<p
            >Dear {{ user.name }},
            </p>
            <p
            >We&nbsp;have received a&nbsp;request to&nbsp;reset your password.
            </p>
            <p
            >This link will expire in&nbsp;\${EMAIL_RESET_PASSWORD_TTL_HOURS}&nbsp;hours and can be&nbsp;used only once.
            </p>`,

        reset_email_title_api_user: 'Forgot your API password?',
        reset_email_body_api_user:
            `<p
            >Dear {{ user.name }},
            </p>
            <p
            >We&nbsp;have received a&nbsp;request to&nbsp;reset your API password.
            </p>
            <p
            >This link will expire in&nbsp;\${EMAIL_RESET_PASSWORD_TTL_HOURS}&nbsp;hours and can be&nbsp;used only once.
            </p>`,

        blocked_account_email_title: `Blocked Access Attempt`,
        blocked_account_email_body:
            `<p
            >Dear {{ user.name }},
            </p>
            <p>
                We&nbsp;detected suspicious activity from the&nbsp;ip:: <strong>{{ user.source_ip }}</strong> from
                <strong>{{ user.city }}</strong>, <strong>{{ user.country }}</strong>.
            </p>
            `,
      },
      verifyFooter: {
        text: '',
        linkText: '',
        linkUrl: '',
      },
      errorFooter: {
        text: '',
        linkText: '',
        linkUrl: '',
      },
      fedSignupErrorFooter: {
        text: '',
        linkText: '',
        linkUrl: '',
      },
      contacts: {
        phones: [''],
        email: '',
        link: undefined,
      },
    },
    {
      name: 'Citeline',
      themeId: THEME_IDS.COMMON,
      logoUrl: `${process.env.SSO_URL || ''}/logos/citiline-logo-dual-color.svg`,
      logoBackground: '#14123b',
      signup: {
        trial: {
          terms:
            `<p>
              During and after your free trial, Citeline will communicate with you by&nbsp;email about your trial
               and very similar products and services that may be&nbsp;of&nbsp;interest to&nbsp;you.
            </p>
            <p>
              We may also contact you with updates, relevant promotions and information about products provided
              by&nbsp;Citeline. Your information will be used in accordance with our
              <a href="https://www.norstella.com/privacy-notice/" target="_blank" rel="noopener nofollow">
                  Privacy notice
              </a> and at any time you can unsubscribe from receiving communications or&nbsp;manage the types of
              communication you receive by&nbsp;following the links in any emails we send you or by emailing
               <a href = "mailto: privacy@norstella.com">privacy@norstella.com</a>
            </p>`,
          header:
            `<h1>Start your 7-day FREE trial to&nbsp;Citeline News and Insights today!</h1>
             <p>Your trial covers one&nbsp;of the following:</p>
             <ul>
                 <li>Pink Sheet</li>
                 <li>Scrip</li>
                 <li>Medtech Insight</li>
                 <li>In Vivo</li>
                 <li>Generics Bulletin</li>
                 <li>HBW Insight</li>
             </ul>
             <hr class="app-delimeter">`,
        },
      },
      resetPassword: {
        clientServicesSection:
          `<p>If&nbsp;you need further assistance contact 
                <a href="https://www.citeline.com/en/contact-us/" target="_blank" rel="noopener nofollow">
                  Customer Support
                </a>
              </p>`,
      },
      emails: {
        logoUrl: `${process.env.SSO_URL || ''}/logos/citiline-logo-dual-color-w212.png`,
        email_footer_team_signature: '{{resolved_application_name}}',
        contactClientServiceContent:
          `<p
                    >If&nbsp;you did not make this request, or&nbsp;if&nbsp;you require further assistance, please contact
                    <a href="mailto:clientservices@citeline.com"
                    >Customer Support.
                    </a>
                  </p>`,
        legalText:
          `Citeline is&nbsp;owned by&nbsp;Caerus
                    US&nbsp;1, Inc.&nbsp;a&nbsp;Delaware
                    corporation with company number 6591595 (&#8220;Citeline&#8221;).
                    Citeline&rsquo;s
                    principal place of&nbsp;business is&nbsp;located at&nbsp;
                    One Pennsylvania Plaza #2505,
                    New York, NY 10119.`,
        copyrightText: `Copyright &copy;&nbsp;Citeline, a Norstella company`,

        verify_email_title: 'Welcome to&nbsp;{{resolved_application_name}}!',
        verify_email_body:
          `<p>You need to&nbsp;verify your email address and complete your profile to&nbsp;finish the sign up&nbsp;process.</p>
           <p>This link will expire in&nbsp;\${EMAIL_VERIFY_TTL_HOURS}&nbsp;hours and can be&nbsp;used only once.</p>`,

        welcome_reset_email_link_text: 'Log in now',

        welcome_reset_email_title_regular_user: 'Welcome to&nbsp;{{resolved_application_name}}!',
        welcome_reset_email_body_regular_user:
          `<p
            >Dear {{ user.name }},
            </p>
            <p
            >You will need to&nbsp;set a&nbsp;password when you first log&nbsp;in.
            </p>
            <p
            >This link will expire in&nbsp;\${EMAIL_RESET_PASSWORD_TTL_HOURS}&nbsp;hours and can be&nbsp;used only once.
           </p>`,

        welcome_reset_email_title_api_user: 'Welcome to&nbsp;{{resolved_application_name}}!',
        welcome_reset_email_body_api_user:
          `<p
            >Dear {{ user.name }},
            </p>
            <p
            >You will need to&nbsp;set a&nbsp;password when you first log&nbsp;in.
            </p>
            <p
            >This link will expire in&nbsp;\${EMAIL_RESET_PASSWORD_TTL_HOURS}&nbsp;hours and can be&nbsp;used only once.
           </p>`,

        reset_email_link_text: 'Reset password',

        reset_email_title_regular_user: 'Forgot your password?',
        reset_email_body_regular_user:
          `<p
            >Dear {{ user.name }},
            </p>
            <p
            >We&nbsp;have received a&nbsp;request to&nbsp;reset your password.
            </p>
            <p
            >This link will expire in&nbsp;\${EMAIL_RESET_PASSWORD_TTL_HOURS}&nbsp;hours and can be&nbsp;used only once.
            </p>`,

        reset_email_title_api_user: 'Forgot your API password?',
        reset_email_body_api_user:
          `<p
            >Dear {{ user.name }},
            </p>
            <p
            >We&nbsp;have received a&nbsp;request to&nbsp;reset your API password.
            </p>
            <p
            >This link will expire in&nbsp;\${EMAIL_RESET_PASSWORD_TTL_HOURS}&nbsp;hours and can be&nbsp;used only once.
            </p>`,

        blocked_account_email_title: `Blocked Access Attempt`,
        blocked_account_email_body:
          `<p
                      >Dear {{ user.name }},
                      </p>
                      <p>
                          We&nbsp;detected suspicious activity from the&nbsp;ip:: <strong>{{ user.source_ip }}</strong> from
                          <strong>{{ user.city }}</strong>, <strong>{{ user.country }}</strong>.
                      </p>
                      <p
                      >If&nbsp;that is&nbsp;your&nbsp;IP address please contact
                          <a href="mailto:clientservices@citeline.com"
                          >Customer Support.
                          </a> Otherwise disregard this email.
                      </p>`,
      },
      verifyFooter: {
        text: 'If you need further assistance contact our',
        linkText: 'Customer Support',
        linkUrl: 'https://www.citeline.com/en/contact-us/',
      },
      errorFooter: {
        text: 'Try again, if the problem persists or you need further assistance contact',
        linkText: 'Customer Support',
        linkUrl: 'https://www.citeline.com/en/contact-us/',
      },
      fedSignupErrorFooter: {
        text: 'If you need further assistance contact our',
        linkText: 'Customer Support',
        linkUrl: 'https://www.citeline.com/en/contact-us/',
      },
      contacts: {
        phones: [
          'Americas: +1 888-670- 8900; +1 908-547- 2200',
          'EMEA: +44 (20) 337 73737',
          'APAC: +61 2 8705 6907',
        ],
        email: 'clientservices@citeline.com',
      },
    },
    {
      name: 'Tala',
      themeId: THEME_IDS.COMMON,
      logoUrl: `${process.env.SSO_URL || ''}/logos/evaluate-norstella-logo.svg`,
      logoBackground: '#352945',
      signup: {
        trial: {
          terms:
            `<p>
              During and after your free trial, Tala will communicate with you by&nbsp;email about your trial
               and very similar products and services that may be&nbsp;of&nbsp;interest to&nbsp;you.
            </p>
            <p>
              We may also contact you with updates, relevant promotions and information about products provided
              by&nbsp;Tala. Your information will be used in accordance with our
              <a href="https://www.norstella.com/privacy-notice/" target="_blank" rel="noopener nofollow">
                  Privacy notice
              </a> and at any time you can unsubscribe from receiving communications or&nbsp;manage the types of
              communication you receive by&nbsp;following the links in any emails we send you or by emailing
               <a href = "mailto: privacy@norstella.com">privacy@norstella.com</a>
            </p>`,
          header:
            `<h1>Start your 7-day FREE trial to&nbsp;Tala today!</h1>
              <p>Your trial covers one&nbsp;of the following:</p>
             <ul>
                 <li>Pink Sheet</li>
                 <li>Scrip</li>
                 <li>Medtech Insight</li>
                 <li>In Vivo</li>
                 <li>Generics Bulletin</li>
                 <li>HBW Insight</li>
             </ul>
             <hr class="app-delimeter">`,
        },
      },
      resetPassword: {
        emailDescription: 'If you are a subscriber, an email containing a link to set up your password will be sent to the email address we hold for you.',
        clientServicesSection:
          `<p>If&nbsp;you need further assistance contact 
            <a href="https://app.evaluate.com/ux/help/contact_us.html" target="_blank" rel="noopener nofollow">
              Customer Success.
            </a>
          </p>`,
      },
      emails: {
        logoUrl: `${process.env.SSO_URL || ''}/logos/evaluate-norstella-logo-w212.png`,
        email_footer_team_signature: '{{resolved_application_name}}',
        contactClientServiceContent:
          `<p
                    >If&nbsp;you did not make this request, or&nbsp;if&nbsp;you require further assistance, please contact
                    <a href="https://app.evaluate.com/ux/help/contact_us.html" target="_blank" rel="noopener nofollow"
                    >Customer Success.
                    </a>
                  </p>`,
        legalText: '',
        copyrightText: '',

        verify_email_title: 'Welcome to&nbsp;{{resolved_application_name}}!',
        verify_email_body:
          `<p>You need to&nbsp;verify your email address and complete your profile to&nbsp;finish the sign up&nbsp;process.</p>
           <p>This link will expire in&nbsp;\${EMAIL_VERIFY_TTL_HOURS}&nbsp;hours and can be&nbsp;used only once.</p>`,

        welcome_reset_email_link_text: 'Log in now',

        welcome_reset_email_title_regular_user: 'Welcome to&nbsp;{{resolved_application_name}}!',
        welcome_reset_email_body_regular_user:
          `<p
            >Dear {{ user.name }},
            </p>
            <p
            >You will need to&nbsp;set a&nbsp;password when you first log&nbsp;in.
            </p>
            <p
            >This link will expire in&nbsp;\${EMAIL_RESET_PASSWORD_TTL_HOURS}&nbsp;hours and can be&nbsp;used only once.
           </p>`,

        welcome_reset_email_title_api_user: 'Welcome to&nbsp;{{resolved_application_name}}!',
        welcome_reset_email_body_api_user:
          `<p
            >Dear {{ user.name }},
            </p>
            <p
            >You will need to&nbsp;set a&nbsp;password when you first log&nbsp;in.
            </p>
            <p
            >This link will expire in&nbsp;\${EMAIL_RESET_PASSWORD_TTL_HOURS}&nbsp;hours and can be&nbsp;used only once.
           </p>`,

        reset_email_link_text: 'Reset password',

        reset_email_title_regular_user: 'Forgot your password?',
        reset_email_body_regular_user:
          `<p
            >Dear {{ user.name }},
            </p>
            <p
            >We&nbsp;have received a&nbsp;request to&nbsp;reset your password.
            </p>
            <p
            >This link will expire in&nbsp;\${EMAIL_RESET_PASSWORD_TTL_HOURS}&nbsp;hours and can be&nbsp;used only once.
            </p>`,

        reset_email_title_api_user: 'Forgot your API password?',
        reset_email_body_api_user:
          `<p
            >Dear {{ user.name }},
            </p>
            <p
            >We&nbsp;have received a&nbsp;request to&nbsp;reset your API password.
            </p>
            <p
            >This link will expire in&nbsp;\${EMAIL_RESET_PASSWORD_TTL_HOURS}&nbsp;hours and can be&nbsp;used only once.
            </p>`,

        blocked_account_email_title: `Blocked Access Attempt`,
        blocked_account_email_body:
          `<p
                      >Dear {{ user.name }},
                      </p>
                      <p>
                          We&nbsp;detected suspicious activity from the&nbsp;ip:: <strong>{{ user.source_ip }}</strong> from
                          <strong>{{ user.city }}</strong>, <strong>{{ user.country }}</strong>.
                      </p>
                      <p
                      >If&nbsp;that is&nbsp;your&nbsp;IP address please contact
                          <a href="https://app.evaluate.com/ux/help/contact_us.html" target="_blank" rel="noopener nofollow"
                          >Customer Success.
                          </a> Otherwise disregard this email.
                      </p>`,
      },
      verifyFooter: {
        text: 'If you need further assistance contact our',
        linkText: 'Customer Success',
        linkUrl: 'https://app.evaluate.com/ux/help/contact_us.html',
      },
      errorFooter: {
        text: 'Try again, if the problem persists or you need further assistance contact',
        linkText: 'Customer Success',
        linkUrl: 'https://app.evaluate.com/ux/help/contact_us.html',
      },
      fedSignupErrorFooter: {
        text: 'If you need further assistance contact our',
        linkText: 'Customer Success',
        linkUrl: 'https://app.evaluate.com/ux/help/contact_us.html',
      },
      contacts: {
        phones: [
          'Evaluate HQ: 44-(0)20-7377-0800',
          'Evaluate Americas: +1-617-573-9450',
          'Evaluate APAC: +81-80-1164-4754',
        ],
        email: 'info@evaluate.com',
      },
    },
    {
      name: 'CompIntel',
      themeId: THEME_IDS.COMMON,
      logoUrl: `${process.env.SSO_URL || ''}/logos/evaluate-norstella-logo.svg`,
      logoBackground: '#352945',
      signup: {
        trial: {
          terms:
            `<p>
              During and after your free trial, CompIntel will communicate with you by&nbsp;email about your trial
               and very similar products and services that may be&nbsp;of&nbsp;interest to&nbsp;you.
            </p>
            <p>
              We may also contact you with updates, relevant promotions and information about products provided
              by&nbsp;CompIntel. Your information will be used in accordance with our
              <a href="https://www.norstella.com/privacy-notice/" target="_blank" rel="noopener nofollow">
                  Privacy notice
              </a> and at any time you can unsubscribe from receiving communications or&nbsp;manage the types of
              communication you receive by&nbsp;following the links in any emails we send you or by emailing
               <a href = "mailto: privacy@norstella.com">privacy@norstella.com</a>
            </p>`,
          header:
            `<h1>Start your 7-day FREE trial to&nbsp;CompIntel today!</h1>
              <p>Your trial covers one&nbsp;of the following:</p>
             <ul>
                 <li>Pink Sheet</li>
                 <li>Scrip</li>
                 <li>Medtech Insight</li>
                 <li>In Vivo</li>
                 <li>Generics Bulletin</li>
                 <li>HBW Insight</li>
             </ul>
             <hr class="app-delimeter">`,
        },
      },
      resetPassword: {
        emailDescription: 'If you are a subscriber, an email containing a link to set up your password will be sent to the email address we hold for you.',
        clientServicesSection:
          `<p>If&nbsp;you need further assistance contact 
            <a href="https://app.evaluate.com/ux/help/contact_us.html" target="_blank" rel="noopener nofollow">
              Customer Success.
            </a>
          </p>`,
      },
      emails: {
        logoUrl: `${process.env.SSO_URL || ''}/logos/evaluate-norstella-logo-w212.png`,
        email_footer_team_signature: 'Evaluate',
        contactClientServiceContent:
          `<p
                    >Please&nbsp;let us know if you require any&nbsp;further&nbsp;assistance by contacting Evaluate
                    <a href="https://app.evaluate.com/ux/help/contact_us.html" target="_blank" rel="noopener nofollow"
                    >Customer Success.
                    </a>
                  </p>`,
        legalText: ``,
        copyrightText: `&copy;&nbsp;\${CURRENT_YEAR}&nbsp;Evaluate, a Norstella Company`,

        verify_email_title: 'Welcome to&nbsp;{{resolved_application_name}}!',
        verify_email_body:
          `<p>You need to&nbsp;verify your email address and complete your profile to&nbsp;finish the sign up&nbsp;process.</p>
           <p>This link will expire in&nbsp;\${EMAIL_VERIFY_TTL_HOURS}&nbsp;hours and can be&nbsp;used only once.</p>`,

        welcome_reset_email_link_text: 'Log in now',

        welcome_reset_email_title_regular_user: 'Welcome to&nbsp;{{resolved_application_name}}!',
        welcome_reset_email_body_regular_user:
          `<p
                    >Your&nbsp;Evaluate subscription is now active. You will need to set a password when you first log in.
                    </p>
                    <p
                    >This link will expire in&nbsp;\${EMAIL_RESET_PASSWORD_TTL_HOURS}&nbsp;hours and can be&nbsp;used only once.
                  </p>`,

        welcome_reset_email_title_api_user: 'Welcome to&nbsp;{{resolved_application_name}}!',
        welcome_reset_email_body_api_user:
          `<p
          >Your&nbsp;Evaluate subscription is now active. You will need to set a password when you first log in.
          </p>
          <p
          >This link will expire in&nbsp;\${EMAIL_RESET_PASSWORD_TTL_HOURS}&nbsp;hours and can be&nbsp;used only once.
        </p>`,

        reset_email_link_text: 'Reset password',
        reset_email_contact_client_service_content: `<p
                    >If&nbsp;you did not make this request, or&nbsp;if&nbsp;you require further assistance, please contact
                    <a href="https://app.evaluate.com/ux/help/contact_us.html" target="_blank" rel="noopener nofollow"
                    >Customer Success.
                    </a>
                  </p>`,

        reset_email_title_regular_user: 'Forgot your password?',
        reset_email_body_regular_user:
          `<p
                  >Dear {{ user.name }},
                  </p>
                  <p
                  >We&nbsp;have received a&nbsp;request to&nbsp;reset your Evaluate password.
                  </p>
                  <p
                  >This link will expire in&nbsp;\${EMAIL_RESET_PASSWORD_TTL_HOURS}&nbsp;hours and can be&nbsp;used only once.
                </p>`,

        reset_email_title_api_user: 'Forgot your API password?',
        reset_email_body_api_user:
          `<p
            >Your&nbsp;Evaluate subscription is now active. You will need to set a password when you first log in.
            </p>
            <p
            >This link will expire in&nbsp;\${EMAIL_RESET_PASSWORD_TTL_HOURS}&nbsp;hours and can be&nbsp;used only once.
          </p>`,

        blocked_account_email_title: `Blocked Access Attempt`,
        blocked_account_email_body:
          `<p
                      >Dear {{ user.name }},
                      </p>
                      <p>
                          We&nbsp;detected suspicious activity from the&nbsp;ip:: <strong>{{ user.source_ip }}</strong> from
                          <strong>{{ user.city }}</strong>, <strong>{{ user.country }}</strong>.
                      </p>
                      <p
                      >If&nbsp;that is&nbsp;your&nbsp;IP address please contact
                      <a href="https://app.evaluate.com/ux/help/contact_us.html" target="_blank" rel="noopener nofollow"
                          >Customer Success.
                          </a> Otherwise disregard this email.
                      </p>`,
      },
      verifyFooter: {
        text: 'If you need further assistance contact our',
        linkText: 'Customer Success',
        linkUrl: 'https://app.evaluate.com/ux/help/contact_us.html',
      },
      errorFooter: {
        text: 'Try again, if the problem persists or you need further assistance contact',
        linkText: 'Customer Success',
        linkUrl: 'https://app.evaluate.com/ux/help/contact_us.html',
      },
      fedSignupErrorFooter: {
        text: 'If you need further assistance contact our',
        linkText: 'Customer Success',
        linkUrl: 'https://app.evaluate.com/ux/help/contact_us.html',
      },
      contacts: {
        phones: [
          'Evaluate HQ: 44-(0)20-7377-0800',
          'Evaluate Americas: +1-617-573-9450',
          'Evaluate APAC: +81-80-1164-4754',
        ],
        email: 'info@evaluate.com',
      },
    },
    {
      name: 'Evaluate',
      themeId: THEME_IDS.COMMON,
      logoUrl: `${process.env.SSO_URL || ''}/logos/evaluate-norstella-logo.svg`,
      logoBackground: '#352945',
      signup: {
        trial: {
          terms:
              `<p>
              During and after your free trial, CompIntel will communicate with you by&nbsp;email about your trial
               and very similar products and services that may be&nbsp;of&nbsp;interest to&nbsp;you.
            </p>
            <p>
              We may also contact you with updates, relevant promotions and information about products provided
              by&nbsp;CompIntel. Your information will be used in accordance with our
              <a href="https://www.norstella.com/privacy-notice/" target="_blank" rel="noopener nofollow">
                  Privacy notice
              </a> and at any time you can unsubscribe from receiving communications or&nbsp;manage the types of
              communication you receive by&nbsp;following the links in any emails we send you or by emailing
               <a href = "mailto:privacy@norstella.com">privacy@norstella.com</a>
            </p>`,
          header:
              `<h1>Start your 7-day FREE trial to&nbsp;CompIntel today!</h1>
              <p>Your trial covers one&nbsp;of the following:</p>
             <ul>
                 <li>Pink Sheet</li>
                 <li>Scrip</li>
                 <li>Medtech Insight</li>
                 <li>In Vivo</li>
                 <li>Generics Bulletin</li>
                 <li>HBW Insight</li>
             </ul>
             <hr class="app-delimeter">`,
        },
      },
      resetPassword: {
        emailDescription: 'If you are a subscriber, an email containing a link to set up your password will be sent to the email address we hold for you.',
        clientServicesSection:
            `<p>If&nbsp;you need further assistance contact 
                <a href = "mailto:clientservices@evaluate.com">Client Services</a>.
          </p>`,
      },
      emails: {
        logoUrl: `${process.env.SSO_URL || ''}/logos/evaluate-norstella-logo-w212.png`,
        email_footer_team_signature: 'Evaluate',
        contactClientServiceContent:
            `<p
                    >Please&nbsp;let us know if you require any&nbsp;further&nbsp;assistance by contacting Evaluate
                        <a href = "mailto:clientservices@evaluate.com">Client Services</a>.
                  </p>`,
        legalText: ``,
        copyrightText: `&copy;&nbsp;\${CURRENT_YEAR}&nbsp;Evaluate, a Norstella Company`,

        verify_email_title: 'Welcome to&nbsp;{{resolved_application_name}}!',
        verify_email_body:
            `<p>You need to&nbsp;verify your email address and complete your profile to&nbsp;finish the sign up&nbsp;process.</p>
           <p>This link will expire in&nbsp;\${EMAIL_VERIFY_TTL_HOURS}&nbsp;hours and can be&nbsp;used only once.</p>`,

        welcome_reset_email_link_text: 'Log in now',

        welcome_reset_email_title_regular_user: 'Welcome to&nbsp;{{resolved_application_name}}!',
        welcome_reset_email_body_regular_user:
            `<p
                    >Your&nbsp;Evaluate subscription is now active. You will need to set a password when you first log in.
                    </p>
                    <p
                    >This link will expire in&nbsp;\${EMAIL_RESET_PASSWORD_TTL_HOURS}&nbsp;hours and can be&nbsp;used only once.
                  </p>`,

        welcome_reset_email_title_api_user: 'Welcome to&nbsp;{{resolved_application_name}}!',
        welcome_reset_email_body_api_user:
            `<p
          >Your&nbsp;Evaluate subscription is now active. You will need to set a password when you first log in.
          </p>
          <p
          >This link will expire in&nbsp;\${EMAIL_RESET_PASSWORD_TTL_HOURS}&nbsp;hours and can be&nbsp;used only once.
        </p>`,

        reset_email_link_text: 'Reset password',
        reset_email_contact_client_service_content: `<p
                    >If&nbsp;you did not make this request, or&nbsp;if&nbsp;you require further assistance, please contact
                    <a href = "mailto:clientservices@evaluate.com">Client Services</a>.
                  </p>`,

        reset_email_title_regular_user: 'Forgot your password?',
        reset_email_body_regular_user:
            `<p
                  >Dear {{ user.name }},
                  </p>
                  <p
                  >We&nbsp;have received a&nbsp;request to&nbsp;reset your Evaluate password.
                  </p>
                  <p
                  >This link will expire in&nbsp;\${EMAIL_RESET_PASSWORD_TTL_HOURS}&nbsp;hours and can be&nbsp;used only once.
                </p>`,

        reset_email_title_api_user: 'Forgot your API password?',
        reset_email_body_api_user:
            `<p
            >Your&nbsp;Evaluate subscription is now active. You will need to set a password when you first log in.
            </p>
            <p
            >This link will expire in&nbsp;\${EMAIL_RESET_PASSWORD_TTL_HOURS}&nbsp;hours and can be&nbsp;used only once.
          </p>`,

        blocked_account_email_title: `Blocked Access Attempt`,
        blocked_account_email_body:
            `<p
                      >Dear {{ user.name }},
                      </p>
                      <p>
                          We&nbsp;detected suspicious activity from the&nbsp;ip:: <strong>{{ user.source_ip }}</strong> from
                          <strong>{{ user.city }}</strong>, <strong>{{ user.country }}</strong>.
                      </p>
                      <p
                      >If&nbsp;that is&nbsp;your&nbsp;IP address please contact
                      <a href = "mailto:clientservices@evaluate.com">Client Services</a>. Otherwise disregard this email.
                      </p>`,
      },
      verifyFooter: {
        text: 'If you need further assistance contact our',
        emailText: "Client Services",
        emailAddress: "clientservices@evaluate.com",
      },
      errorFooter: {
        text: 'Try again, if the problem persists or you need further assistance contact',
        emailText: "Client Services",
        emailAddress: "clientservices@evaluate.com",
      },
      fedSignupErrorFooter: {
        text: 'If you need further assistance contact our',
        emailText: "Client Services",
        emailAddress: "clientservices@evaluate.com",
      },
      contacts: {
        phones: [
          'Evaluate HQ: 44-(0)20-7377-0800',
          'Evaluate Americas: +1-617-573-9450',
          'Evaluate APAC: +81-80-1164-4754',
        ],
        email: 'info@evaluate.com',
      },
    },
    {
      name: 'NorstellaLinQ',
      themeId: THEME_IDS.COMMON,
      logoUrl: `${process.env.SSO_URL || ''}/logos/norstellaLinQ/norstella-linq.svg`,
      logoBackground: '#352945',
      signup: {
        trial: {
          terms:
              `<p>
              During and after your free trial, CompIntel will communicate with you by&nbsp;email about your trial
               and very similar products and services that may be&nbsp;of&nbsp;interest to&nbsp;you.
            </p>
            <p>
              We may also contact you with updates, relevant promotions and information about products provided
              by&nbsp;CompIntel. Your information will be used in accordance with our
              <a href="https://www.norstella.com/privacy-notice/" target="_blank" rel="noopener nofollow">
                  Privacy notice
              </a> and at any time you can unsubscribe from receiving communications or&nbsp;manage the types of
              communication you receive by&nbsp;following the links in any emails we send you or by emailing
               <a href = "mailto:privacy@norstella.com">privacy@norstella.com</a>
            </p>`,
          header:
              `<h1>Start your 7-day FREE trial to&nbsp;CompIntel today!</h1>
              <p>Your trial covers one&nbsp;of the following:</p>
             <ul>
                 <li>Pink Sheet</li>
                 <li>Scrip</li>
                 <li>Medtech Insight</li>
                 <li>In Vivo</li>
                 <li>Generics Bulletin</li>
                 <li>HBW Insight</li>
             </ul>
             <hr class="app-delimeter">`,
        },
      },
      resetPassword: {
        emailDescription: 'If you are a subscriber, an email containing a link to set up your password will be sent to the email address we hold for you.',
        clientServicesSection:
            `<p>If&nbsp;you need further assistance contact 
            <a href = "mailto:clientservices@evaluate.com">Client Services</a>.
          </p>`,
      },
      emails: {
        logoUrl: `${process.env.SSO_URL || ''}/logos/norstellaLinQ/norstella-linq.png`,
        email_footer_team_signature: 'Evaluate',
        contactClientServiceContent:
            `<p
                    >Please&nbsp;let us know if you require any&nbsp;further&nbsp;assistance by contacting Evaluate
                    <a href = "mailto:clientservices@evaluate.com">Client Services</a>.
                  </p>`,
        legalText: ``,
        copyrightText: `&copy;&nbsp;\${CURRENT_YEAR}&nbsp;Evaluate, a Norstella Company`,

        verify_email_title: 'Welcome to&nbsp;{{resolved_application_name}}!',
        verify_email_body:
            `<p>You need to&nbsp;verify your email address and complete your profile to&nbsp;finish the sign up&nbsp;process.</p>
           <p>This link will expire in&nbsp;\${EMAIL_VERIFY_TTL_HOURS}&nbsp;hours and can be&nbsp;used only once.</p>`,

        welcome_reset_email_link_text: 'Log in now',

        welcome_reset_email_title_regular_user: 'Welcome to&nbsp;{{resolved_application_name}}!',
        welcome_reset_email_body_regular_user:
            `<p
                    >Your&nbsp;Evaluate subscription is now active. You will need to set a password when you first log in.
                    </p>
                    <p
                    >This link will expire in&nbsp;\${EMAIL_RESET_PASSWORD_TTL_HOURS}&nbsp;hours and can be&nbsp;used only once.
                  </p>`,

        welcome_reset_email_title_api_user: 'Welcome to&nbsp;{{resolved_application_name}}!',
        welcome_reset_email_body_api_user:
            `<p
          >Your&nbsp;Evaluate subscription is now active. You will need to set a password when you first log in.
          </p>
          <p
          >This link will expire in&nbsp;\${EMAIL_RESET_PASSWORD_TTL_HOURS}&nbsp;hours and can be&nbsp;used only once.
        </p>`,

        reset_email_link_text: 'Reset password',
        reset_email_contact_client_service_content: `<p
                    >If&nbsp;you did not make this request, or&nbsp;if&nbsp;you require further assistance, please contact
                    <a href = "mailto:clientservices@evaluate.com">Client Services</a>.
                  </p>`,

        reset_email_title_regular_user: 'Forgot your password?',
        reset_email_body_regular_user:
            `<p
                  >Dear {{ user.name }},
                  </p>
                  <p
                  >We&nbsp;have received a&nbsp;request to&nbsp;reset your Evaluate password.
                  </p>
                  <p
                  >This link will expire in&nbsp;\${EMAIL_RESET_PASSWORD_TTL_HOURS}&nbsp;hours and can be&nbsp;used only once.
                </p>`,

        reset_email_title_api_user: 'Forgot your API password?',
        reset_email_body_api_user:
            `<p
            >Your&nbsp;Evaluate subscription is now active. You will need to set a password when you first log in.
            </p>
            <p
            >This link will expire in&nbsp;\${EMAIL_RESET_PASSWORD_TTL_HOURS}&nbsp;hours and can be&nbsp;used only once.
          </p>`,

        blocked_account_email_title: `Blocked Access Attempt`,
        blocked_account_email_body:
            `<p
                      >Dear {{ user.name }},
                      </p>
                      <p>
                          We&nbsp;detected suspicious activity from the&nbsp;ip:: <strong>{{ user.source_ip }}</strong> from
                          <strong>{{ user.city }}</strong>, <strong>{{ user.country }}</strong>.
                      </p>
                      <p
                      >If&nbsp;that is&nbsp;your&nbsp;IP address please contact
                      <a href = "mailto:clientservices@evaluate.com">Client Services</a>. Otherwise disregard this email.
                      </p>`,
      },
      verifyFooter: {
        text: 'If you need further assistance contact our',
        emailText: "Client Services",
        emailAddress: "clientservices@evaluate.com",
      },
      errorFooter: {
        text: 'Try again, if the problem persists or you need further assistance contact',
        emailText: "Client Services",
        emailAddress: "clientservices@evaluate.com",
      },
      fedSignupErrorFooter: {
        text: 'If you need further assistance contact our',
        emailText: "Client Services",
        emailAddress: "clientservices@evaluate.com",
      },
      contacts: {
        phones: [
          'Evaluate HQ: 44-(0)20-7377-0800',
          'Evaluate Americas: +1-617-573-9450',
          'Evaluate APAC: +81-80-1164-4754',
        ],
        email: 'info@evaluate.com',
      },
    },
    {
      name: 'Clinical',
      themeId: THEME_IDS.COMMON,
      logoUrl: `${process.env.SSO_URL || ''}/logos/citiline-logo-dual-color.svg`,
      logoBackground: '#14123b',
      signup: {
        trial: {
          terms:
            `<p>
              During and after your free trial, Clinical will communicate with you by&nbsp;email about your trial
               and very similar products and services that may be&nbsp;of&nbsp;interest to&nbsp;you.
            </p>
            <p>
              We may also contact you with updates, relevant promotions and information about products provided
              by&nbsp;Citeline. Your information will be used in accordance with our
              <a href="#" target="_blank" rel="noopener nofollow">
                  Privacy notice
              </a> and at any time you can unsubscribe from receiving communications or&nbsp;manage the types of
              communication you receive by&nbsp;following the links in any emails we send you or by emailing
               <a href = "#">privacy@norstella.com</a>
            </p>`,
          header:
            `<h1>Start your 7-day FREE trial to&nbsp;Clinical News and Insights today!</h1>
             <p>Your trial covers one&nbsp;of the following:</p>
             <ul>
                 <li>Pink Sheet</li>
                 <li>Scrip</li>
                 <li>Medtech Insight</li>
                 <li>In Vivo</li>
                 <li>Generics Bulletin</li>
                 <li>HBW Insight</li>
             </ul>
             <hr class="app-delimeter">`,
        },
      },
      resetPassword: {
        clientServicesSection:
          `<p>If&nbsp;you need further assistance contact 
                <a href="#" target="_blank" rel="noopener nofollow">
                  Client Services
                </a>
              </p>`,
      },
      emails: {
        logoUrl: `${process.env.SSO_URL || ''}/logos/citiline-logo-dual-color-w212.png`,
        email_footer_team_signature: '{{resolved_application_name}}',
        contactClientServiceContent:
          `<p
                    >If&nbsp;you did not make this request, or&nbsp;if&nbsp;you require further assistance, please contact
                    <a href="#"
                    >Client Services.
                    </a>
                  </p>`,
        legalText:
          `Placeholder Clinical`,
        copyrightText: `Placeholder Copyright &copy;&nbsp;`,

        verify_email_title: 'Welcome to&nbsp;{{resolved_application_name}}!',
        verify_email_body:
          `<p>You need to&nbsp;verify your email address and complete your profile to&nbsp;finish the sign up&nbsp;process.</p>
           <p>This link will expire in&nbsp;\${EMAIL_VERIFY_TTL_HOURS}&nbsp;hours and can be&nbsp;used only once.</p>`,

        welcome_reset_email_link_text: 'Log in now',

        welcome_reset_email_title_regular_user: 'Welcome to&nbsp;{{resolved_application_name}}!',
        welcome_reset_email_body_regular_user:
          `<p
            >Dear {{ user.name }},
            </p>
            <p
            >You will need to&nbsp;set a&nbsp;password when you first log&nbsp;in.
            </p>
            <p
            >This link will expire in&nbsp;\${EMAIL_RESET_PASSWORD_TTL_HOURS}&nbsp;hours and can be&nbsp;used only once.
           </p>`,

        welcome_reset_email_title_api_user: 'Welcome to&nbsp;{{resolved_application_name}}!',
        welcome_reset_email_body_api_user:
          `<p
            >Dear {{ user.name }},
            </p>
            <p
            >You will need to&nbsp;set a&nbsp;password when you first log&nbsp;in.
            </p>
            <p
            >This link will expire in&nbsp;\${EMAIL_RESET_PASSWORD_TTL_HOURS}&nbsp;hours and can be&nbsp;used only once.
           </p>`,

        reset_email_link_text: 'Reset password',

        reset_email_title_regular_user: 'Forgot your password?',
        reset_email_body_regular_user:
          `<p
            >Dear {{ user.name }},
            </p>
            <p
            >We&nbsp;have received a&nbsp;request to&nbsp;reset your password.
            </p>
            <p
            >This link will expire in&nbsp;\${EMAIL_RESET_PASSWORD_TTL_HOURS}&nbsp;hours and can be&nbsp;used only once.
            </p>`,

        reset_email_title_api_user: 'Forgot your API password?',
        reset_email_body_api_user:
          `<p
            >Dear {{ user.name }},
            </p>
            <p
            >We&nbsp;have received a&nbsp;request to&nbsp;reset your API password.
            </p>
            <p
            >This link will expire in&nbsp;\${EMAIL_RESET_PASSWORD_TTL_HOURS}&nbsp;hours and can be&nbsp;used only once.
            </p>`,

        blocked_account_email_title: `Blocked Access Attempt`,
        blocked_account_email_body:
          `<p
                      >Dear {{ user.name }},
                      </p>
                      <p>
                          We&nbsp;detected suspicious activity from the&nbsp;ip:: <strong>{{ user.source_ip }}</strong> from
                          <strong>{{ user.city }}</strong>, <strong>{{ user.country }}</strong>.
                      </p>
                      <p
                      >If&nbsp;that is&nbsp;your&nbsp;IP address please contact
                          <a href="#"
                          >Client Services.
                          </a> Otherwise disregard this email.
                      </p>`,
      },
      verifyFooter: {
        text: 'If you need further assistance contact our',
        linkText: 'Client Services',
        linkUrl: 'https://www.citeline.com/en/contact-us/',
      },
      errorFooter: {
        text: 'Try again, if the problem persists or you need further assistance contact',
        linkText: 'Customer Support',
        linkUrl: 'https://www.citeline.com/en/contact-us/',
      },
      fedSignupErrorFooter: {
        text: 'If you need further assistance contact our',
        linkText: 'Customer Success',
        linkUrl: 'https://app.evaluate.com/ux/help/contact_us.html',
      },
      contacts: {
        phones: [
          'Americas: +1 888-670- 8900; +1 908-547- 2200',
          'EMEA: +44 (20) 337 73737',
          'APAC: +61 2 8705 6907',
        ],
        email: 'clientservices@citeline.com',
      },
    },
    {
      name: 'Stratintel',
      themeId: THEME_IDS.COMMON,
      logoUrl: `${process.env.SSO_URL || ''}/logos/evaluate-norstella-logo.svg`,
      logoBackground: '#352945',
      signup: {
        trial: {
          terms:
            `<p>
              During and after your free trial, Stratintel will communicate with you by&nbsp;email about your trial
               and very similar products and services that may be&nbsp;of&nbsp;interest to&nbsp;you.
            </p>
            <p>
              We may also contact you with updates, relevant promotions and information about products provided
              by&nbsp;Citeline. Your information will be used in accordance with our
              <a href="#" target="_blank" rel="noopener nofollow">
                  Privacy notice
              </a> and at any time you can unsubscribe from receiving communications or&nbsp;manage the types of
              communication you receive by&nbsp;following the links in any emails we send you or by emailing
               <a href = "#">privacy@norstella.com</a>
            </p>`,
          header:
            `<h1>Start your 7-day FREE trial to&nbsp;Stratintel News and Insights today!</h1>
             <p>Your trial covers one&nbsp;of the following:</p>
             <ul>
                 <li>Pink Sheet</li>
                 <li>Scrip</li>
                 <li>Medtech Insight</li>
                 <li>In Vivo</li>
                 <li>Generics Bulletin</li>
                 <li>HBW Insight</li>
             </ul>
             <hr class="app-delimeter">`,
        },
      },
      resetPassword: {
        emailDescription: 'If you are a subscriber, an email containing a link to set up your password will be sent to the email address we hold for you.',
        clientServicesSection:
          `<p>If&nbsp;you need further assistance contact 
            <a href="https://app.evaluate.com/ux/help/contact_us.html" target="_blank" rel="noopener nofollow">
              Customer Success.
            </a>
          </p>`,
      },
      emails: {
        logoUrl: `${process.env.SSO_URL || ''}/logos/evaluate-norstella-logo-w212.png`,
        email_footer_team_signature: '{{resolved_application_name}}',
        contactClientServiceContent:
          `<p
                    >If&nbsp;you did not make this request, or&nbsp;if&nbsp;you require further assistance, please contact
                    <a href="https://app.evaluate.com/ux/help/contact_us.html" target="_blank" rel="noopener nofollow"
                    >Customer Success.
                    </a>
                  </p>`,
        legalText: ``,
        copyrightText: ``,

        verify_email_title: 'Welcome to&nbsp;{{resolved_application_name}}!',
        verify_email_body:
          `<p>You need to&nbsp;verify your email address and complete your profile to&nbsp;finish the sign up&nbsp;process.</p>
           <p>This link will expire in&nbsp;\${EMAIL_VERIFY_TTL_HOURS}&nbsp;hours and can be&nbsp;used only once.</p>`,

        welcome_reset_email_link_text: 'Log in now',

        welcome_reset_email_title_regular_user: 'Welcome to&nbsp;{{resolved_application_name}}!',
        welcome_reset_email_body_regular_user:
          `<p
            >Dear {{ user.name }},
            </p>
            <p
            >You will need to&nbsp;set a&nbsp;password when you first log&nbsp;in.
            </p>
            <p
            >This link will expire in&nbsp;\${EMAIL_RESET_PASSWORD_TTL_HOURS}&nbsp;hours and can be&nbsp;used only once.
           </p>`,

        welcome_reset_email_title_api_user: 'Welcome to&nbsp;{{resolved_application_name}}!',
        welcome_reset_email_body_api_user:
          `<p
            >Dear {{ user.name }},
            </p>
            <p
            >You will need to&nbsp;set a&nbsp;password when you first log&nbsp;in.
            </p>
            <p
            >This link will expire in&nbsp;\${EMAIL_RESET_PASSWORD_TTL_HOURS}&nbsp;hours and can be&nbsp;used only once.
           </p>`,

        reset_email_link_text: 'Reset password',

        reset_email_title_regular_user: 'Forgot your password?',
        reset_email_body_regular_user:
          `<p
            >Dear {{ user.name }},
            </p>
            <p
            >We&nbsp;have received a&nbsp;request to&nbsp;reset your password.
            </p>
            <p
            >This link will expire in&nbsp;\${EMAIL_RESET_PASSWORD_TTL_HOURS}&nbsp;hours and can be&nbsp;used only once.
            </p>`,

        reset_email_title_api_user: 'Forgot your API password?',
        reset_email_body_api_user:
          `<p
            >Dear {{ user.name }},
            </p>
            <p
            >We&nbsp;have received a&nbsp;request to&nbsp;reset your API password.
            </p>
            <p
            >This link will expire in&nbsp;\${EMAIL_RESET_PASSWORD_TTL_HOURS}&nbsp;hours and can be&nbsp;used only once.
            </p>`,

        blocked_account_email_title: `Blocked Access Attempt`,
        blocked_account_email_body:
          `<p
                      >Dear {{ user.name }},
                      </p>
                      <p>
                          We&nbsp;detected suspicious activity from the&nbsp;ip:: <strong>{{ user.source_ip }}</strong> from
                          <strong>{{ user.city }}</strong>, <strong>{{ user.country }}</strong>.
                      </p>
                      <p
                      >If&nbsp;that is&nbsp;your&nbsp;IP address please contact
                      <a href="https://app.evaluate.com/ux/help/contact_us.html" target="_blank" rel="noopener nofollow"
                          >Customer Success.
                          </a> Otherwise disregard this email.
                      </p>`,
      },
      verifyFooter: {
        text: 'If you need further assistance contact our',
        linkText: 'Customer Success',
        linkUrl: 'https://app.evaluate.com/ux/help/contact_us.html',
      },
      errorFooter: {
        text: 'Try again, if the problem persists or you need further assistance contact',
        linkText: 'Customer Success',
        linkUrl: 'https://app.evaluate.com/ux/help/contact_us.html',
      },
      fedSignupErrorFooter: {
        text: 'If you need further assistance contact our',
        linkText: 'Customer Success',
        linkUrl: 'https://app.evaluate.com/ux/help/contact_us.html',
      },
      contacts: {
        phones: [
          'Evaluate HQ: 44-(0)20-7377-0800',
          'Evaluate Americas: +1-617-573-9450',
          'Evaluate APAC: +81-80-1164-4754',
        ],
        email: 'info@evaluate.com',
      },
    },
    {
      name: 'Fusion',
      themeId: THEME_IDS.COMMON,
      logoUrl: `${process.env.SSO_URL || ''}/logos/fusion-logo.svg`,
      logoBackground: '#1C2633',
      signup: {
        trial: {
          terms:
            `<p>
              During and after your free trial, Citeline will communicate with you by&nbsp;email about your trial
               and very similar products and services that may be&nbsp;of&nbsp;interest to&nbsp;you.
            </p>
            <p>
              We may also contact you with updates, relevant promotions and information about products provided
              by&nbsp;Citeline. Your information will be used in accordance with our
              <a href="https://www.norstella.com/privacy-notice/" target="_blank" rel="noopener nofollow">
                  Privacy notice
              </a> and at any time you can unsubscribe from receiving communications or&nbsp;manage the types of
              communication you receive by&nbsp;following the links in any emails we send you or by emailing
               <a href = "mailto: privacy@norstella.com">privacy@norstella.com</a>
            </p>`,
          header:
            `<h1>Start your 7-day FREE trial to&nbsp;Citeline News and Insights today!</h1>
             <p>Your trial covers one&nbsp;of the following:</p>
             <ul>
                 <li>Pink Sheet</li>
                 <li>Scrip</li>
                 <li>Medtech Insight</li>
                 <li>In Vivo</li>
                 <li>Generics Bulletin</li>
                 <li>HBW Insight</li>
             </ul>
             <hr class="app-delimeter">`,
        },
      },
      resetPassword: {
        clientServicesSection: ``,
      },
      emails: {
        logoUrl: `${process.env.SSO_URL || ''}/logos/fusion-logo-w212.png`,
        email_footer_team_signature: '{{resolved_application_name}}',
        contactClientServiceContent:
          `<p>If&nbsp;you did not make this request, please ignore this email.</p>`,
        legalText: '',
        copyrightText: '',

        verify_email_title: 'Welcome to&nbsp;{{resolved_application_name}}!',
        verify_email_body:
          `<p>You need to&nbsp;verify your email address and complete your profile to&nbsp;finish the sign up&nbsp;process.</p>
           <p>This link will expire in&nbsp;\${EMAIL_VERIFY_TTL_HOURS}&nbsp;hours and can be&nbsp;used only once.</p>`,

        welcome_reset_email_link_text: 'Log in now',

        welcome_reset_email_title_regular_user: 'Welcome to&nbsp;{{resolved_application_name}}!',
        welcome_reset_email_body_regular_user:
          `<p
            >Dear {{ user.name }},
            </p>
            <p
            >You will need to&nbsp;set a&nbsp;password when you first log&nbsp;in.
            </p>
            <p
            >This link will expire in&nbsp;\${EMAIL_RESET_PASSWORD_TTL_HOURS}&nbsp;hours and can be&nbsp;used only once.
           </p>`,

        welcome_reset_email_title_api_user: 'Welcome to&nbsp;{{resolved_application_name}}!',
        welcome_reset_email_body_api_user:
          `<p
            >Dear {{ user.name }},
            </p>
            <p
            >You will need to&nbsp;set a&nbsp;password when you first log&nbsp;in.
            </p>
            <p
            >This link will expire in&nbsp;\${EMAIL_RESET_PASSWORD_TTL_HOURS}&nbsp;hours and can be&nbsp;used only once.
           </p>`,

        reset_email_link_text: 'Reset password',

        reset_email_title_regular_user: 'Forgot your password?',
        reset_email_body_regular_user:
          `<p
            >Dear {{ user.name }},
            </p>
            <p
            >We&nbsp;have received a&nbsp;request to&nbsp;reset your password.
            </p>
            <p
            >This link will expire in&nbsp;\${EMAIL_RESET_PASSWORD_TTL_HOURS}&nbsp;hours and can be&nbsp;used only once.
            </p>`,

        reset_email_title_api_user: 'Forgot your API password?',
        reset_email_body_api_user:
          `<p
            >Dear {{ user.name }},
            </p>
            <p
            >We&nbsp;have received a&nbsp;request to&nbsp;reset your API password.
            </p>
            <p
            >This link will expire in&nbsp;\${EMAIL_RESET_PASSWORD_TTL_HOURS}&nbsp;hours and can be&nbsp;used only once.
            </p>`,

        blocked_account_email_title: 'Blocked Access Attempt',
        blocked_account_email_body:
          `<p
              >Dear {{ user.name }},
              </p>
              <p>
                  We&nbsp;detected suspicious activity from the&nbsp;ip:: <strong>{{ user.source_ip }}</strong> from
                  <strong>{{ user.city }}</strong>, <strong>{{ user.country }}</strong>.
              </p>`,
      },
      verifyFooter: {
        text: 'If you need further assistance contact our',
        linkText: 'Client Services',
        linkUrl: 'https://www.citeline.com/en/contact-us/',
      },
      errorFooter: {
        text: 'Try again, if the problem persists or you need further assistance contact',
        linkText: 'Customer Support',
        linkUrl: 'https://www.citeline.com/en/contact-us/',
      },
      fedSignupErrorFooter: {
        text: 'If you need further assistance contact our',
        linkText: 'Customer Success',
        linkUrl: 'https://app.evaluate.com/ux/help/contact_us.html',
      },
      contacts: {
        phones: [
          'Americas: +1 888-670- 8900; +1 908-547- 2200',
          'EMEA: +44 (20) 337 73737',
          'APAC: +61 2 8705 6907',
        ],
        email: 'clientservices@citeline.com',
      },
    },
    {
      name: 'PharmaAPI',
      themeId: THEME_IDS.COMMON,
      logoUrl: `${process.env.SSO_URL || ''}/logos/citiline-logo-dual-color.svg`,
      logoBackground: '#14123b',
      signup: {
        trial: {
          terms:
            `<p>
              During and after your free trial, Citeline will communicate with you by&nbsp;email about your trial
               and very similar products and services that may be&nbsp;of&nbsp;interest to&nbsp;you.
            </p>
            <p>
              We may also contact you with updates, relevant promotions and information about products provided
              by&nbsp;Citeline. Your information will be used in accordance with our
              <a href="https://www.norstella.com/privacy-notice/" target="_blank" rel="noopener nofollow">
                  Privacy notice
              </a> and at any time you can unsubscribe from receiving communications or&nbsp;manage the types of
              communication you receive by&nbsp;following the links in any emails we send you or by emailing
               <a href = "mailto: privacy@norstella.com">privacy@norstella.com</a>
            </p>`,
          header:
            `<h1>Start your 7-day FREE trial to&nbsp;Citeline News and Insights today!</h1>
             <p>Your trial covers one&nbsp;of the following:</p>
             <ul>
                 <li>Pink Sheet</li>
                 <li>Scrip</li>
                 <li>Medtech Insight</li>
                 <li>In Vivo</li>
                 <li>Generics Bulletin</li>
                 <li>HBW Insight</li>
             </ul>
             <hr class="app-delimeter">`,
        },
      },
      resetPassword: {
        clientServicesSection:
          `<p>If&nbsp;you need further assistance contact 
                <a href="https://www.citeline.com/en/contact-us/" target="_blank" rel="noopener nofollow">
                  Customer Support
                </a>
              </p>`,
      },
      emails: {
        logoUrl: `${process.env.SSO_URL || ''}/logos/citiline-logo-dual-color-w212.png`,
        email_footer_team_signature: '{{resolved_application_name}}',
        contactClientServiceContent:
          `<p
                    >If&nbsp;you did not make this request, or&nbsp;if&nbsp;you require further assistance, please contact
                    <a href="mailto:clientservices@citeline.com"
                    >Customer Support.
                    </a>
                  </p>`,
        legalText:
          `Citeline is&nbsp;owned by&nbsp;Caerus
                    US&nbsp;1, Inc.&nbsp;a&nbsp;Delaware
                    corporation with company number 6591595 (&#8220;Citeline&#8221;).
                    Citeline&rsquo;s
                    principal place of&nbsp;business is&nbsp;located at&nbsp;
                    One Pennsylvania Plaza #2505,
                    New York, NY 10119.`,
        copyrightText: `Copyright &copy;&nbsp;Citeline, a Norstella company`,

        verify_email_title: 'Welcome to&nbsp;{{resolved_application_name}}!',
        verify_email_body:
          `<p>You need to&nbsp;verify your email address and complete your profile to&nbsp;finish the sign up&nbsp;process.</p>
           <p>This link will expire in&nbsp;\${EMAIL_VERIFY_TTL_HOURS}&nbsp;hours and can be&nbsp;used only once.</p>`,

        welcome_reset_email_link_text: 'Log in now',

        welcome_reset_email_title_regular_user: 'Welcome to&nbsp;{{resolved_application_name}}!',
        welcome_reset_email_body_regular_user:
          `<p
            >Dear {{ user.name }},
            </p>
            <p
            >You will need to&nbsp;set a&nbsp;password when you first log&nbsp;in.
            </p>
            <p
            >This link will expire in&nbsp;\${EMAIL_RESET_PASSWORD_TTL_HOURS}&nbsp;hours and can be&nbsp;used only once.
           </p>`,

        welcome_reset_email_title_api_user: 'Welcome to&nbsp;{{resolved_application_name}}!',
        welcome_reset_email_body_api_user:
          `<p
            >Dear {{ user.name }},
            </p>
            <p
            >You will need to&nbsp;set a&nbsp;password when you first log&nbsp;in.
            </p>
            <p
            >This link will expire in&nbsp;\${EMAIL_RESET_PASSWORD_TTL_HOURS}&nbsp;hours and can be&nbsp;used only once.
           </p>`,

        reset_email_link_text: 'Reset password',

        reset_email_title_regular_user: 'Forgot your password?',
        reset_email_body_regular_user:
          `<p
            >Dear {{ user.name }},
            </p>
            <p
            >We&nbsp;have received a&nbsp;request to&nbsp;reset your password.
            </p>
            <p
            >This link will expire in&nbsp;\${EMAIL_RESET_PASSWORD_TTL_HOURS}&nbsp;hours and can be&nbsp;used only once.
            </p>`,

        reset_email_title_api_user: 'Forgot your API password?',
        reset_email_body_api_user:
          `<p
            >Dear {{ user.name }},
            </p>
            <p
            >We&nbsp;have received a&nbsp;request to&nbsp;reset your API password.
            </p>
            <p
            >This link will expire in&nbsp;\${EMAIL_RESET_PASSWORD_TTL_HOURS}&nbsp;hours and can be&nbsp;used only once.
            </p>`,

        blocked_account_email_title: 'Account locked',
        blocked_account_email_body:
          `<p
                      >Dear {{ user.name }},
                      </p>
                      <p>
                          For security reasons, your account has been locked after too many failed log in attempts.
                      </p>
                      <p
                      >Contact <a href="mailto:clientservices@citeline.com"
                          >Customer Support
                          </a> for more information and how to unlock your account.
                      </p>`,
      },
      verifyFooter: {
        text: 'If you need further assistance contact our',
        linkText: 'Customer Support',
        linkUrl: 'https://www.citeline.com/en/contact-us/',
      },
      errorFooter: {
        text: 'Try again, if the problem persists or you need further assistance contact',
        linkText: 'Customer Support',
        linkUrl: 'https://www.citeline.com/en/contact-us/',
      },
      fedSignupErrorFooter: {
        text: 'If you need further assistance contact our',
        linkText: 'Customer Support',
        linkUrl: 'https://www.citeline.com/en/contact-us/',
      },
      contacts: {
        phones: [
          'Americas: +1 888-670- 8900; +1 908-547- 2200',
          'EMEA: +44 (20) 337 73737',
          'APAC: +61 2 8705 6907',
        ],
        email: 'clientservices@citeline.com',
      },
      customLoginText: 'To access our API Documentation SSO and non-SSO users ' +
        'need their corporate email with associated API entitlements and an API password. ' +
        'Please message <a href="mailto:clientservices@citeline.com">clientservices@citeline.com</a> ' +
        'if you are unsure or require access.',
    },
    {
      name: 'C+D',
      themeId: THEME_IDS.BLUE,
      logoUrl: `${process.env.SSO_URL || ''}/logos/cd/cd.svg`,
      logoBackground: '#ffffff',
      signup: {
        full: {
          isConsentRequired: true,
          terms:
            `<input type="checkbox" name="consent" id="consent">
            <label for="consent" class="app-terms">
    <p>
        I&nbsp;have read and understand the <a href="https://www.citeline.com/en/terms-of-use" target="_blank" rel="noopener nofollow">Terms and Conditions</a> of&nbsp;Website Use and agree to&nbsp;be&nbsp;bound by&nbsp;all of&nbsp;its terms. In&nbsp;the event the
        Customer has existing terms of&nbsp;sale with the Licensor, the existing terms of&nbsp;sale shall govern.
    </p>
    <p>By&nbsp;registering for C+D you have complete control over what we&nbsp;send you and when. View our <a href="https://www.chemistanddruggist.co.uk/more/help/contact-promise/" target="_blank" rel="noopener nofollow">customer contact promise</a>. You can amend your details at&nbsp;any time in&nbsp;My&nbsp;Account. Tick this box to&nbsp;let&nbsp;us know
        you&rsquo;ve read our <a href="https://www.norstella.com/cookie-management/" target="_blank" rel="noopener nofollow">privacy policy</a>*</p>
</label>`,
        },
        trial: {
          terms: ``,
          header: ``,
        },
      },
      resetPassword: {
        clientServicesSection: `<p>If&nbsp;you need further assistance contact 
                <a href="mailto:updatemydetails@chemistanddruggist.co.uk">
                  Customer Support
                </a>
              </p>`,
      },
      emails: {
        logoUrl: `${process.env.SSO_URL || ''}/logos/cd/cd.png`,
        has_header_delimiter: true,
        email_footer_team_signature: '{{resolved_application_name}}',
        contactClientServiceContent:
          `<p
                    >If&nbsp;you did not make this request, or&nbsp;if&nbsp;you require further assistance, please contact
                    <a style="color:#257EA4; text-decoration: underline" href="mailto:updatemydetails@chemistanddruggist.co.uk"
                    >Customer Support.
                    </a>
                  </p>`,
        legal_text:
          `Chemist+Druggist is&nbsp;a&nbsp;trading division of&nbsp;Citeline, a&nbsp;Norstella company and operates as&nbsp;part of&nbsp;C&amp;D Intelligence U.K. Limited. C&amp;D Intelligence U.K. Limited is&nbsp;a&nbsp;company registered in&nbsp;England and Wales (Registration number 13793264) with its registered office at&nbsp;3&nbsp;More London Riverside, London SE1 2AQ`,
        copyright_text: `&copy;&nbsp;\${CURRENT_YEAR}&nbsp;Caerus US 1, Inc`,

        verify_email_title: 'Welcome to&nbsp;{{resolved_application_name}}!',
        verify_email_body:
          `<p>You need to&nbsp;verify your email address and complete your profile to&nbsp;finish the sign up&nbsp;process.</p>
           <p>This link will expire in&nbsp;\${EMAIL_VERIFY_TTL_HOURS}&nbsp;hours and can be&nbsp;used only once.</p>`,

        welcome_reset_email_link_text: 'Log in now',

        welcome_reset_email_title_regular_user: 'Welcome to&nbsp;{{resolved_application_name}}!',
        welcome_reset_email_body_regular_user:
          `<p
            >Dear {{ user.name }},
            </p>
            <p
            >You will need to&nbsp;set a&nbsp;password when you first log&nbsp;in.
            </p>
            <p
            >This link will expire in&nbsp;\${EMAIL_RESET_PASSWORD_TTL_HOURS}&nbsp;hours and can be&nbsp;used only once.
           </p>`,

        welcome_reset_email_title_api_user: 'Welcome to&nbsp;{{resolved_application_name}}!',
        welcome_reset_email_body_api_user:
          `<p
            >Dear {{ user.name }},
            </p>
            <p
            >You will need to&nbsp;set a&nbsp;password when you first log&nbsp;in.
            </p>
            <p
            >This link will expire in&nbsp;\${EMAIL_RESET_PASSWORD_TTL_HOURS}&nbsp;hours and can be&nbsp;used only once.
           </p>`,

        reset_email_link_text: 'Reset password',

        reset_email_title_regular_user: 'Forgot your password?',
        reset_email_body_regular_user:
          `<p
            >Dear {{ user.name }},
            </p>
            <p
            >We&nbsp;have received a&nbsp;request to&nbsp;reset your password.
            </p>
            <p
            >This link will expire in&nbsp;\${EMAIL_RESET_PASSWORD_TTL_HOURS}&nbsp;hours and can be&nbsp;used only once.
            </p>`,

        reset_email_title_api_user: 'Forgot your API password?',
        reset_email_body_api_user:
          `<p
            >Dear {{ user.name }},
            </p>
            <p
            >We&nbsp;have received a&nbsp;request to&nbsp;reset your API password.
            </p>
            <p
            >This link will expire in&nbsp;\${EMAIL_RESET_PASSWORD_TTL_HOURS}&nbsp;hours and can be&nbsp;used only once.
            </p>`,

        blocked_account_email_title: `Blocked Access Attempt`,
        blocked_account_email_body:
          `<p
                      >Dear {{ user.name }},
                      </p>
                      <p>For security reasons, your account has been locked after too many failed log in attempts.
                      </p>
                      <p
                      >Contact <a style="color:#257EA4; text-decoration: underline" href="mailto:updatemydetails@chemistanddruggist.co.uk"
                    >Customer Support</a> for more information and how to unlock your account.</p>
                      `,
      },
      verifyFooter: {
        text: 'If you need further assistance contact our',
        linkText: 'Customer Support',
        linkUrl: 'mailto:updatemydetails@chemistanddruggist.co.uk',
      },
      errorFooter: {
        text: 'Try again, if the problem persists or you need further assistance contact',
        linkText: 'Customer Support',
        linkUrl: 'mailto:updatemydetails@chemistanddruggist.co.uk',
      },
      fedSignupErrorFooter: {
        text: 'If you need further assistance contact our',
        linkText: 'Customer Support',
        linkUrl: 'mailto:updatemydetails@chemistanddruggist.co.uk',
      },
      contacts: {
        link: {
          url: 'mailto:updatemydetails@chemistanddruggist.co.uk',
          text: 'Customer Support',
        },
      },
    },
    {
      name: 'C+D-Community',
      themeId: THEME_IDS.BLUE,
      logoUrl: `${process.env.SSO_URL || ''}/logos/cd-community/cd-community.svg`,
      logoBackground: '#ffffff',
      signup: {
        full: {
          isConsentRequired: true,
          terms:
            `<input type="checkbox" name="consent" id="consent">
            <label for="consent" class="app-terms">
    <p>
        I&nbsp;have read and understand the <a href="https://www.citeline.com/en/terms-of-use" target="_blank" rel="noopener nofollow">Terms and Conditions</a> of&nbsp;Website Use and agree to&nbsp;be&nbsp;bound by&nbsp;all of&nbsp;its terms. In&nbsp;the event the
        Customer has existing terms of&nbsp;sale with the Licensor, the existing terms of&nbsp;sale shall govern.
    </p>
    <p>By&nbsp;registering for C+D you have complete control over what we&nbsp;send you and when. View our <a href="https://www.chemistanddruggist.co.uk/more/help/contact-promise/" target="_blank" rel="noopener nofollow">customer contact promise</a>. You can amend your details at&nbsp;any time in&nbsp;My&nbsp;Account. Tick this box to&nbsp;let&nbsp;us know
        you&rsquo;ve read our <a href="https://www.norstella.com/cookie-management/" target="_blank" rel="noopener nofollow">privacy policy</a>*</p>
</label>`,
        },
        trial: {
          terms: ``,
          header: ``,
        },
      },
      resetPassword: {
        clientServicesSection: `<p>If&nbsp;you need further assistance contact 
                <a href="mailto:updatemydetails@chemistanddruggist.co.uk">
                  Customer Support
                </a>
              </p>`,
      },
      emails: {
        logoUrl: `${process.env.SSO_URL || ''}/logos/cd-community/cd-community.png`,
        has_header_delimiter: true,
        email_footer_team_signature: '{{resolved_application_name}}',
        contactClientServiceContent:
          `<p
                    >If&nbsp;you did not make this request, or&nbsp;if&nbsp;you require further assistance, please contact
                    <a style="color:#257EA4; text-decoration: underline" href="mailto:updatemydetails@chemistanddruggist.co.uk"
                    >Customer Support.
                    </a>
                  </p>`,
        legal_text:
          `Chemist+Druggist is&nbsp;a&nbsp;trading division of&nbsp;Citeline, a&nbsp;Norstella company and operates as&nbsp;part of&nbsp;C&amp;D Intelligence U.K. Limited. C&amp;D Intelligence U.K. Limited is&nbsp;a&nbsp;company registered in&nbsp;England and Wales (Registration number 13793264) with its registered office at&nbsp;3&nbsp;More London Riverside, London SE1 2AQ`,
        copyright_text: `&copy;&nbsp;\${CURRENT_YEAR}&nbsp;Caerus US 1, Inc`,

        verify_email_title: 'Welcome to&nbsp;{{resolved_application_name}}!',
        verify_email_body:
          `<p>You need to&nbsp;verify your email address and complete your profile to&nbsp;finish the sign up&nbsp;process.</p>
           <p>This link will expire in&nbsp;\${EMAIL_VERIFY_TTL_HOURS}&nbsp;hours and can be&nbsp;used only once.</p>`,

        welcome_reset_email_link_text: 'Log in now',

        welcome_reset_email_title_regular_user: 'Welcome to&nbsp;{{resolved_application_name}}!',
        welcome_reset_email_body_regular_user:
          `<p
            >Dear {{ user.name }},
            </p>
            <p
            >You will need to&nbsp;set a&nbsp;password when you first log&nbsp;in.
            </p>
            <p
            >This link will expire in&nbsp;\${EMAIL_RESET_PASSWORD_TTL_HOURS}&nbsp;hours and can be&nbsp;used only once.
           </p>`,

        welcome_reset_email_title_api_user: 'Welcome to&nbsp;{{resolved_application_name}}!',
        welcome_reset_email_body_api_user:
          `<p
            >Dear {{ user.name }},
            </p>
            <p
            >You will need to&nbsp;set a&nbsp;password when you first log&nbsp;in.
            </p>
            <p
            >This link will expire in&nbsp;\${EMAIL_RESET_PASSWORD_TTL_HOURS}&nbsp;hours and can be&nbsp;used only once.
           </p>`,

        reset_email_link_text: 'Reset password',

        reset_email_title_regular_user: 'Forgot your password?',
        reset_email_body_regular_user:
          `<p
            >Dear {{ user.name }},
            </p>
            <p
            >We&nbsp;have received a&nbsp;request to&nbsp;reset your password.
            </p>
            <p
            >This link will expire in&nbsp;\${EMAIL_RESET_PASSWORD_TTL_HOURS}&nbsp;hours and can be&nbsp;used only once.
            </p>`,

        reset_email_title_api_user: 'Forgot your API password?',
        reset_email_body_api_user:
          `<p
            >Dear {{ user.name }},
            </p>
            <p
            >We&nbsp;have received a&nbsp;request to&nbsp;reset your API password.
            </p>
            <p
            >This link will expire in&nbsp;\${EMAIL_RESET_PASSWORD_TTL_HOURS}&nbsp;hours and can be&nbsp;used only once.
            </p>`,

        blocked_account_email_title: `Blocked Access Attempt`,
        blocked_account_email_body:
          `<p
                      >Dear {{ user.name }},
                      </p>
                      <p>For security reasons, your account has been locked after too many failed log in attempts.
                      </p>
                      <p
                      >Contact <a style="color:#257EA4; text-decoration: underline" href="mailto:updatemydetails@chemistanddruggist.co.uk"
                    >Customer Support</a> for more information and how to unlock your account.</p>
                      `,
      },
      verifyFooter: {
        text: 'If you need further assistance contact our',
        linkText: 'Customer Support',
        linkUrl: 'mailto:updatemydetails@chemistanddruggist.co.uk',
      },
      errorFooter: {
        text: 'Try again, if the problem persists or you need further assistance contact',
        linkText: 'Customer Support',
        linkUrl: 'mailto:updatemydetails@chemistanddruggist.co.uk',
      },
      fedSignupErrorFooter: {
        text: 'If you need further assistance contact our',
        linkText: 'Customer Support',
        linkUrl: 'mailto:updatemydetails@chemistanddruggist.co.uk',
      },
      contacts: {
        link: {
          url: 'mailto:updatemydetails@chemistanddruggist.co.uk',
          text: 'Customer Support',
        },
      },
    },
  ],
  themes: [
    {
      id: THEME_IDS.COMMON,
      cssVariables: {
        [AVAILABLE_THEME_VARIABLES.PRIMARY_COLOR]: '#0f847c',
        [AVAILABLE_THEME_VARIABLES.BASE_FOCUS_COLOR]: '#0f847c',
        [AVAILABLE_THEME_VARIABLES.LINK_COLOR]: '#0f847c',
        [AVAILABLE_THEME_VARIABLES.SUCCESS_COLOR]: '#0f847c',
      },
    },
    {
      id: THEME_IDS.BLUE,
      cssVariables: {
        [AVAILABLE_THEME_VARIABLES.PRIMARY_COLOR]: '#257EA4',
        [AVAILABLE_THEME_VARIABLES.BASE_FOCUS_COLOR]: '#257EA4',
        [AVAILABLE_THEME_VARIABLES.LINK_COLOR]: '#257EA4',
        [AVAILABLE_THEME_VARIABLES.SUCCESS_COLOR]: '#257EA4',
      },
    },
  ],
}

module.exports = config
